import html2pdf from 'html2pdf.js';

export async function elementToPdf(element, opts) {
  const opt = {
    margin: 0.5, // inches
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    useCORS: true,
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    ...opts,
  };

  // Generate and save the PDF
  await html2pdf().set(opt).from(element).save();
}
