import { HuiAlert, HuiLoading, HuiPanel } from 'handle-ui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import HuiPanelPayment from '../../../components/checkout/HuiPanelPayment';
import PortalLogo from '../../../components/portal/PortalLogo';
import { forceCloseLoading } from '../../../redux/LoadingSlice';
import { fetchBlob } from '../../../utils/utilsFetch';
import { elementToPdf } from '../../../utils/utilsPage';
import HuiFullPaymentsDetail from '../../private/history/HuiFullPaymentsDetail';

const PublicThanks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { payment } = location?.state || {};

  useEffect(() => {
    if (!payment) {
      navigate('/invalid-request', { state: { fields: {} } });
    }
  });

  // control loading
  useEffect(() => {
    async function loading() {
      await dispatch(forceCloseLoading({}));
    }

    loading();
  }, []);

  if (!payment) {
    return <HuiLoading isOpen />;
  }

  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-12 text-center pb-5">
          <PortalLogo className="hui-payment-logo" />
        </div>
        <div className="col-lg-10 offset-lg-1 mb-4">
          <HuiPanel className="hui-payment-panel overflow-hidden position-relative d-flex flex-column mb-4">
            <HuiPanelPayment className="hui-bg-gray-light h-100 d-flex flex-grow-1">
              <div className="text-center p-5">
                <h1 className="text-center hui-title mb-2 hui-color-green">Thank you for your payment!</h1>
                <div className="hui-text-lg">
                  Payment confirmation code: <br />
                  <strong>{payment?.code}</strong>
                </div>
              </div>

              <div className="border-top p-5 buttons-print">
                <div className="row offset-lg-2 col-lg-8">
                  <div className="col-12">
                    <HuiAlert
                      border
                      className="mb-4"
                      title=" A confirmation email has been sent to the email on file"
                      radius="xl"
                      icon="green-check"
                      shadow
                      background
                      white
                    />
                  </div>
                  <div className="col-lg-6 d-flex flex-column mb-4 mb-lg-0">
                    <button onClick={handlePrint} type="submit" className="hui-btn hui-btn-xl ">
                      Print
                    </button>
                  </div>

                  <div className="col-lg-6 d-flex flex-column">
                    <button onClick={handleDownload} type="submit" className="hui-btn hui-btn-xl ">
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </HuiPanelPayment>
          </HuiPanel>
        </div>
        <div className="col-lg-10 offset-lg-1">
          <HuiFullPaymentsDetail payment={payment} />
        </div>
      </div>
    </div>
  );

  function handlePrint() {
    window.print();
  }

  async function handleDownload() {
    let element;
    try {
      element = document.querySelector('.hui-app');
      hideButtons(element);

      const img = element.querySelector('img');
      console.log('Element:', element);

      const imgUrl = img.src;
      const blob = await fetchBlob(img.src);
      const objectURL = URL.createObjectURL(blob);
      img.src = objectURL;

      // Define PDF options
      const opt = {
        filename: `Payment-${payment.code}.pdf`,
      };

      await elementToPdf(element, opt);

      img.onload = () => {
        URL.revokeObjectURL(objectURL);
        console.log('Object URL revoked');
      };

      img.setAttribute('src', imgUrl);
      toastr.success(`Pdf downloaded successfully`);
    } catch (error) {
      console.log(error);
      toastr.error(`Error downloading Pdf`);
    } finally {
      showButtons(element);
    }
  }

  function hideButtons(element) {
    if (!element) {
      return;
    }

    const buttonEl = element.querySelector('.buttons-print');
    if (buttonEl) {
      buttonEl.setAttribute('style', 'display: none');
    }

    const panel = element.querySelector('.hui-payment-panel');
    if (panel) {
      panel.setAttribute('style', 'min-height: inherit;');
    }
  }

  function showButtons(element) {
    if (!element) {
      return;
    }

    const buttonEl = element.querySelector('.buttons-print');
    if (buttonEl) {
      buttonEl?.setAttribute('style', '');
    }
  }
};
export default PublicThanks;
